import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFile = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M14 1a1.01 1.01 0 0 1 .25.031l.03.009c.03.009.061.02.091.031l.027.012a.914.914 0 0 1 .195.112l.02.016.012.009.012.009.015.012.055.052 6 6a1.006 1.006 0 0 1 .152.195l.018.032a.932.932 0 0 1 .04.082l.012.027c.012.03.022.06.031.091l.008.03c.008.03.015.062.02.093l.009.078L21 8v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3h8zm-1 2H6a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9h-5a1 1 0 0 1-.993-.883L13 8V3zm3 13a1 1 0 0 1 .117 1.993L16 18H8a1 1 0 0 1-.117-1.993L8 16h8zm0-4a1 1 0 0 1 .117 1.993L16 14H8a1 1 0 0 1-.117-1.993L8 12h8zm-6-4a1 1 0 0 1 .117 1.993L10 10H8a1 1 0 0 1-.117-1.993L8 8h2zm5-3.585V7h2.586L15 4.415z"
        fill="#000080"
      />
    </g>
  </svg>
);
export default SvgFile;
